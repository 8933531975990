<!-- Displays the text contained in 'dictKey' of the current i18n locale, replacing all {currentYear} tags with the current year  -->
<template>
  <!-- Utilize i18n-t element so that interpolation can be accomplished
  in order to insert superscript tags where desired -->
  <i18n-t :keypath="keypath">
    <template v-slot:currentYear>
      {{ currentYear }}
    </template>
  </i18n-t>
</template>

<script>
export default {
  name: 'I18nWithCurrentYear',
  props: {
    keypath: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
}
</script>

<style scoped></style>
