<template>
  <v-app>
    <v-main>
      <!-- Header -->
      <v-toolbar
        class="header"
        flat
        style="background-color: rgba(0, 0, 0, 0.9)"
      >
        <v-toolbar-title class="ml-10">
          <div style="width: fit-content">
            <a href="https://www.sony.com/">
              <v-img :width="100" src="@/assets/images/sony_logo.svg" />
            </a>
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <!-- Body -->
      <v-container class="pa-sm-8" style="max-width: 1200px">
        <router-view />
      </v-container>

      <!-- SRD Banner -->
      <div
        class="subFooter py-4 px-10 d-flex align-baseline justify-space-between"
        flat
        style="background-color: black"
      >
        <div>
          <v-img :width="100" src="@/assets/images/srd_logo.png" />
        </div>
        <div>
          <a target="_blank" :href="$t('DIC_APP_BANNER_TRADEMARK_LINK')">
            {{ t('DIC_APP_BANNER_TRADEMARK_TEXT') }}
          </a>
        </div>
      </div>

      <!-- Footer -->
      <v-footer
        tag="div"
        class="pa-5 d-flex flex-column align-start flex-md-row"
        style="background-color: rgb(31, 32, 36); color: white"
      >
        <div
          class="footerDiv1 d-flex flex-column align-items flex-md-row order-md-1"
        >
          <a
            class="mb-6 mb-md-0 mr-md-6"
            target="_blank"
            :href="$t('DIC_FOOTER_TERMS_OF_USE_LINK')"
          >
            {{ t('DIC_FOOTER_TERMS_OF_USE_TEXT') }}
          </a>
          <a
            class="mb-6 mb-md-0 mr-md-6"
            target="_blank"
            :href="$t('DIC_FOOTER_PRIVACY_POLICY_LINK')"
          >
            {{ t('DIC_FOOTER_PRIVACY_POLICY_TEXT') }}
          </a>
          <a
            class="mb-6 mb-md-0 mr-md-6"
            target="_blank"
            :href="$t('DIC_FOOTER_PRODUCT_SUPPORT_LINK')"
          >
            {{ t('DIC_FOOTER_PRODUCT_SUPPORT_TEXT') }}
          </a>
        </div>
        <div class="mb-6 mb-md-0 mr-md-6 order-md-0">
          <a
            class="groupPortalLink"
            target="_blank"
            :href="$t('DIC_FOOTER_PORTAL_SITE_LINK')"
          >
            <v-img
              :width="180"
              src="@/assets/images/sony_group_portal_site_link.png"
            />
          </a>
        </div>
        <v-spacer class="d-none d-md-block order-md-2" />
        <div class="order-md-3" style="color: rgb(137, 137, 145)">
          <i18n-with-current-year keypath="DIC_FOOTER_COPYRIGHT" />
        </div>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
// Plugings
import { useI18n } from 'vue-i18n'

import I18nWithCurrentYear from './components/ui-components/I18nWithCurrentYear.vue'

export default {
  name: 'App',

  components: {
    I18nWithCurrentYear,
  },

  // Setup I18n Plugin
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'global',
    })
    return { t }
  },
}
</script>

<style>
/* Import fonts */
@font-face {
  font-family: 'SST W20 Roman';
  src: url('@/assets/fonts/SST_W20_Roman.woff2') format('woff2');
}

/* General */
:root {
  font-family: 'SST W20 Roman';
  font-size: 16px;
}
h1 {
  font-size: 28px;
}
/* Links */
a {
  color: white;
  text-decoration: none;
  width: fit-content;
}
a:hover {
  /* text-decoration: underline; */
  border-bottom: 1px solid white;
  opacity: 0.8;
  cursor: pointer;
}

/* Layout */
.v-main {
  display: flex !important;
  flex-direction: column;
  background-color: #f2f2f2;
  scrollbar-color: #bbb #f2f2f2;
}
.v-toolbar,
.subFooter,
.v-footer {
  flex-grow: 0;
}
.v-container {
  flex-grow: 1; /* expand to fill viewport */
}

/* Footer */
.v-footer {
  font-size: 14px;
  line-height: 21px;
}
.footerDiv1 a {
  padding: 2px auto;
}
.groupPortalLink:hover {
  opacity: 0.8;
}

/* xs only */
@media only screen and (max-width: 599px) {
  .appInfoTable {
    font-size: 12px;
  }
}
/* sm & up */
@media only screen and (min-width: 600px) {
}
/* md & up */
@media only screen and (min-width: 960px) {
  .v-footer {
    font-size: 12px;
  }
}
/* lg & up */
@media only screen and (min-width: 1280px) {
  h1 {
    font-size: 32px;
  }
}
</style>
