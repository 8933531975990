export default {
  "DIC_APP_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Viewer for Sketchfab"])},
  "DIC_APP_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Viewer for Sketchfab is a library composed of millions of free 3D assets from Sketchfab."])},
  "DIC_APP_COPYRIGHT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sketchfab, Inc. Sketchfab and the Sketchfab logo are trademarks or registered trademarks of Sketchfab, Inc. in the United States of America and elsewhere. All rights reserved."])},
  "DIC_APP_RELEASE_NOTES_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Notes"])},
  "DIC_APP_RELEASE_VERSION_1_SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v1.0.0 - 6/5/2023"])},
  "DIC_APP_RELEASE_VERSION_1_FEATURE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search and view free models directly from website"])},
  "DIC_APP_RELEASE_VERSION_1_FEATURE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model control with mouse"])},
  "DIC_APP_RELEASE_VERSION_2_SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v1.1.0 - estimated 2/15/2024"])},
  "DIC_APP_RELEASE_VERSION_2_FEATURE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["two factor authentication"])},
  "DIC_APP_RELEASE_VERSION_2_FEATURE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["increase model loading speed"])},
  "DIC_APP_DOWNLOAD_BUTTON_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "DIC_APP_USER_MANUAL_DOWNLOAD_BTN_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User's Manual Download"])},
  "DIC_APP_USER_MANUAL_DOWNLOAD_PATH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/USER-MANUAL.pdf"])},
  "DIC_APP_USER_MANUAL_DOWNLOAD_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Viewer for Sketchfab Product Manual.pdf"])},
  "DIC_APP_DETAILS_SUPPORTED_MODELS_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported Models"])},
  "DIC_APP_DETAILS_SUPPORTED_MODELS_VALUE1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELF-SR1"])},
  "DIC_APP_DETAILS_SUPPORTED_MODELS_VALUE2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELF-SR2"])},
  "DIC_APP_DETAILS_RECOMMENDED_PC_SPECS_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended PC Spec"])},
  "DIC_APP_DETAILS_RECOMMENDED_PC_SPECS_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows10(64bit)/Windows11, Intel i7-9700 or higher, GeForce RTX2070Super/Radeon5700 or higher, Memory 16GB or higher, SSD is recommended"])},
  "DIC_APP_DETAILS_PAID_OR_FREE_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid/Free"])},
  "DIC_APP_DETAILS_PAID_OR_FREE_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "DIC_APP_DETAILS_EXTERNAL_DEVICE_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Device"])},
  "DIC_APP_DETAILS_EXTERNAL_DEVICE_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USB Game Controller, Keyboard, mouse for windows"])},
  "DIC_APP_DETAILS_LANGUAGE_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "DIC_APP_DETAILS_LANGUAGE_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English, Japanese, Canadian French, Chinese"])},
  "DIC_APP_DETAILS_DEVELOPER_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
  "DIC_APP_DETAILS_DEVELOPER_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sony Corporation"])},
  "DIC_APP_DETAILS_PUBLISHER_HEADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
  "DIC_APP_DETAILS_PUBLISHER_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sony Corporation"])},
  "DIC_APP_BANNER_TRADEMARK_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trademarks"])},
  "DIC_APP_BANNER_TRADEMARK_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.sony.net/Products/Developer-Spatial-Reality-display/en/Trademarks.html"])},
  "DIC_FOOTER_TERMS_OF_USE_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "DIC_FOOTER_PRIVACY_POLICY_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "DIC_FOOTER_PRODUCT_SUPPORT_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Support"])},
  "DIC_FOOTER_COPYRIGHT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright ", _interpolate(_named("currentYear")), " Sony Corporation"])},
  "DIC_FOOTER_PORTAL_SITE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.sony.com/en/"])},
  "DIC_FOOTER_TERMS_OF_USE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.sony.net/terms-of-use/"])},
  "DIC_FOOTER_PRIVACY_POLICY_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.sony.net/privacy-policy/"])},
  "DIC_FOOTER_PRODUCT_SUPPORT_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://pro.sony/ue_US/support-services/all-support-services"])},
  "DIC_EULA_PAGE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D Viewer for Sketchfab END USER LICENSE AGREEMENT"])},
  "DIC_EULA_AGREE_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the END USER LICENSE AGREEMENT"])},
  "DIC_EULA_PAGE_DOWNLOAD_BTN_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "DIC_EULA_PAGE_DOWNLOAD_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://assets.pro.sony.eu/Web/supportcontent/Sketchfab_for_SRD_Select.zip"])},
  "DIC_EULA_PAGE_MD5_HASH_NOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MD5 hash of download:"])},
  "DIC_EULA_PAGE_MD5_HASH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4dc64619a611fc3371365e24d74643c3"])}
}