/**
 * @file Contains all routing information for the application
 */
import { createRouter, createWebHistory } from 'vue-router'

/**
 * For each given path, defines the correct component/view to render in the 
 * <router-view> object in App.vue
 */
const routes = [
  { path: '/', name: 'home', component: () => import("@/views/HomePage.vue") },
  // Dynamically import below routes: import statement only called when arrow function called (upon navigation),
  // thus the below pages are lazy loaded (loaded on demand) - called route level code-splitting
  // (this generates a separate chunk (about.[hash].js) for this route)
  { path: '/eula', name: 'eula', component: () => import("@/views/EulaPage.vue")},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
